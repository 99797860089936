<template>
  <div id="particulars-box" class="particulars">
    <div class="salary-collect">
      <h5>{{ year }}年终奖金（税前）</h5>
      <p class="amount" v-if="salaryInfo">{{ salaryInfo.preTaxAmount }}<span class="unit">元</span></p>
      <h3 v-if="!salaryInfo" class="notDataText">暂无数据</h3>
      <span>感谢为公司的付出，辛苦啦...</span>
    </div>

    <div class="detail-bottom" v-if="detail">
      <ul>
        <li>温馨提示:</li>
        <li @click="close">×</li>
      </ul>
      <p>年终奖属于敏感信息，请注意保密。</p>
      <p>如对年终奖有异议，请联系公司人事，重新核对并下发工资条。</p>
    </div>

    <div class="detail-Pages">
      <div v-if="salaryInfo">
        <ul>
          <!-- <li v-if="verifyIsShow(salaryInfo.postLevelFactor)">
            <span>岗位等级系数:</span>
            <span>{{ salaryInfo.postLevelFactor }}</span>
          </li> -->
          <li v-if="verifyIsShow(salaryInfo.personalFactor)">
            <span>个人出勤系数:</span>
            <span>{{ salaryInfo.personalFactor }}</span>
          </li>
          <li v-if="verifyIsShow(salaryInfo.personalKpiFactor)">
            <span>个人绩效系数:</span>
            <span>{{ salaryInfo.personalKpiFactor }}</span>
          </li>
          <li v-if="verifyIsShow(salaryInfo.personalKpiLevel)">
            <span>个人绩效等级:</span
            ><span>{{ salaryInfo.personalKpiLevel }}</span>
          </li>
          <li v-if="verifyIsShow(salaryInfo.preTaxAmount)">
            <span>税前年终奖金:</span>
            <span>{{ salaryInfo.preTaxAmount }}</span>
          </li>

          <li v-if="verifyIsShow(salaryInfo.personalTaxAmount)">
            <span>个人所得税:</span>
            <span>{{ salaryInfo.personalTaxAmount }}</span>
          </li>
          <li v-if="verifyIsShow(salaryInfo.afterTaxAmount)">
            <span>税后年终奖金:</span>
            <span>{{ salaryInfo.afterTaxAmount }}</span>
          </li>
        </ul>
      </div>

      <h3 v-if="!salaryInfo" class="notDataText">暂无数据</h3>
    </div>
    <loading-temp :loading="loading"> </loading-temp>
  </div>
</template>

<script>
import { getDetail } from "../../server/user";
import loadingTemp from "../../components/loading";
import { mapState } from "vuex";
import { watermark } from "../../unit/units";
export default {
  name: "particulars",
  data() {
    return {
      salaryInfo: null,
      loading: false,
      detail: true,
      year:''
    };
  },
  components: {
    loadingTemp,
  },
  created() {
    this.loading = true;
    this.initData(this.$route.params.id);
    this.year=this.$route.query.year
  },
  mounted() {
    setTimeout(() => {
      this.$nextTick(() => {
        watermark.setWatermark({
          id: "particulars-box",
          userName: this.userInfo.nick_name,
        });
      });
    }, 10);
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  methods: {
    /**
     * 初始数据
     */
    initData(id) {
      this.loading = true;
      getDetail(id)
        .then((res) => {
          this.salaryInfo = res.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    /**
     *
     */
    verifyIsShow(val) {
      return Number(val) !== 0;
    },
    close() {
      this.detail = false;
    },
  },
};
</script>

<style scoped lang="scss">
.particulars {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  background: linear-gradient(#487ee2, rgb(243, 243, 248));
  padding-top: 20px;
  .particulars-time {
    width: 150px;
    height: 30px;
    background-color: #a2beef;
    font-size: 14px;
    text-align: center;
    line-height: 30px;
    margin: 0 auto;
    border-radius: 10px;
    margin-bottom: 10px;
    color: #fff;
  }

  .salary-collect {
    width: 340px;
    height: 110px;
    background-color: #fff;
    margin: 0 auto;
    border-radius: 14px;
    padding-top: 15px;
    text-align: center;
    font-size: 12px;
    margin-bottom: 10px;

    .amount {
      font-size: 36px;
      color: rgba(0, 0, 0, 0.8);
      font-weight: 700;
      margin-top: 10px;
      margin-bottom: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      .unit {
        font-size: 34px;
        margin-left: 2px;
        color: inherit;
      }
    }
  }

  .salary-collect h5 {
    color: #787878;
  }

  .salary-collect span {
    font-size: 14px;
    color: #487ee2;
  }

  .detail-Pages {
    width: 340px;
    height: 420px;
    background-color: #ffffff;
    margin: 0 auto;
    // margin-top: 20px;
    // padding-top: 20px;
    overflow: scroll;
    border-radius: 6px;

    ul {
      li {
        span {
          &:first-child {
            text-align: right;
            width: 40%;
          }
          &:last-child {
            text-align: center;
            width:55%;
          }
        }
      }
    }

    // p {
    //   width: 300px;
    //   height: 40px;
    //   line-height: 40px;
    //   display: flex;
    //   justify-content: space-between;
    //   margin: 0 auto;
    //   padding-left: 25px;
    //   padding-right: 15px;
    //   font-size:22px;
    // }
  }

  .detail-Pages p:first-child {
    border-bottom: 1px dashed rgba(0, 0, 0, 0.3);
  }

  .detail-Pages p:last-child {
    border-top: 1px dashed rgba(0, 0, 0, 0.3);
  }

  .detail-Pages li:last-child {
    border: none;
  }

  .detail-Pages ul li {
    width: 280px;
    height: 40px;
    display: flex;
    justify-content: space-between;
    line-height: 40px;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.3);
    // padding-left: 20px;
    padding-right: 20px;
    margin: 0 auto;
    font-size: 14px;
  }

  .detail-Pages ul li span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  //.detail-Pages ul li span:last-child{
  //  font-weight:700;
  //}

  .detail-bottom {
    font-size: 12px;
    text-align: center;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.6);
    margin-top: 5px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    background-color: #fffbe6;
    p {
      font-weight: 600;
    }
    ul {
      display: flex;
      justify-content: space-between;
      li {
        padding: 5px;
      }
      li:first-child {
        font-weight: 900;
      }
      li:last-child {
        font-size: 28px;
      }
    }
  }

  .notDataText {
    text-align: center;
    margin: 20px 0;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.5);
  }
}
</style>
